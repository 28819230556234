import _ from 'lodash';

import { slices, store } from 'onescreen/store';
import { Maybe, ObjectWithoutType, Pagination, Query } from 'onescreen/types';

import { BaseAPI } from '../base';
import { Org } from './org';
import { ServiceDrop } from './service_drops';
import { BaselineMonth } from '../util';

/** ======================== Types ========================================= */
type CommonAttrs = {
  id: number;
  name: string;
  address: string;
  address_2: string;
  city: string;
  state: string;
  zipcode: string;
  latitude?: number;
  longitude?: number;
  object_type: 'Site';
  org?: Org['id'];
  service_drops: Array<ServiceDrop['id']>;
  baselines: Array<BaselineMonth['id']>; // Irradiance baselines
};

export declare namespace Site {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs {}

  // Network interface
  export namespace API {
    export type SideloadedResponses = {
      service_drops?: ServiceDrop.Raw[];
      orgs?: Org.Raw[];
      baseline_months?: BaselineMonth.Raw[];
    };

    export type ListParams = RetrieveParams & Query.PaginationParams;
    export type ListResponse = Pagination.Response.Raw<{ sites: Site.Raw[] }>;
    export type ListResult = Pagination.Response.Parsed<Site>;

    export type RetrieveParams = Query.DynamicRestParams;
    export type RetrieveResponse = { site: Site.Raw } & SideloadedResponses;
    export type RetrieveResult = { site: Site };

    export type CreateParams = Pick<
      Raw,
      'name' | 'address' | 'address_2' | 'city' | 'zipcode' | 'state' | 'org'
    >;
    export type UpdateParams =
      | Pick<Raw, 'name' | 'address' | 'address_2' | 'city' | 'zipcode' | 'state'>
      | Pick<Raw, 'baselines'>;
  }
}

/** ======================== API =========================================== */
class SiteAPI extends BaseAPI {
  private static route = BaseAPI.endpoints.v1.site;

  static async list(params?: Site.API.ListParams): Promise<Site.API.ListResult> {
    const { json } = await this.get<Site.API.ListResponse>(this.route, params);

    // Parse response
    const sites = this.parsePaginationSet(json, ({ sites }) => Site.fromObjects(sites));
    store.dispatch(slices.data.updateModels(sites.results));
    return sites;
  }

  static async retrieve(
    id: Site['id'],
    params?: Site.API.RetrieveParams
  ): Promise<Site.API.RetrieveResult> {
    const { json } = await this.get<Site.API.RetrieveResponse>(this.route(id), params);

    // Parse response
    const site = new Site(json.site);
    const { service_drops, orgs, baselines } = this.parseSideloadedObjects(json);
    store.dispatch(slices.data.updateModels(site, service_drops, orgs, baselines));
    return { site };
  }

  static async create(params?: Site.API.CreateParams): Promise<Site.API.RetrieveResult> {
    const { json } = await this.post<Site.API.RetrieveResponse>(this.route, params);

    // Parse response
    const site = new Site(json.site);
    store.dispatch(slices.data.updateModels(site));
    site.Org?.addSite(site.id);
    return { site };
  }

  static async update(
    id: Site['id'],
    body: Site.API.UpdateParams,
    params?: Site.API.RetrieveParams
  ): Promise<Site.API.RetrieveResult> {
    const { json } = await this.patch<Site.API.RetrieveResponse>(this.route(id), body, params);

    // Parse response
    const site = new Site(json.site);
    const { baselines } = this.parseSideloadedObjects(json);
    store.dispatch(slices.data.updateModels(site, baselines));
    return { site };
  }

  static parseSideloadedObjects(json: Site.API.SideloadedResponses) {
    return {
      service_drops: ServiceDrop.fromObjects(json.service_drops),
      orgs: Org.fromObjects(json.orgs),
      baselines: BaselineMonth.fromObjects(json.baseline_months),
    };
  }
}

/** ======================== Model ========================================= */
export interface Site extends CommonAttrs {}
export class Site {
  /** ====================== Static fields ================================= */
  static api = SiteAPI;
  static readonly rawFields = [
    'address',
    'address_2',
    'city',
    'state',
    'id',
    'latitude',
    'longitude',
    'name',
    'service_drops',
    'zipcode',
    'baselines',
    'org',
  ] as const;

  /**
   * Parses an array of raw Site objects by passing each in turn to Site.fromObject. Note that
   * calling `raw.map(this.fromObject)` does not work, because TypeScript is unable to resolve the
   * correct overload for fromObject when it is passed to the `map` method. This is a TypeScript
   * design limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {Site.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: Site.Raw[]): Site[];
  static fromObjects(raw: Maybe<Site.Raw[]>): Maybe<Site[]>;
  static fromObjects(raw: Maybe<Site.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: Site.Raw): Site;
  static fromObject(raw: Maybe<Site.Raw>): Maybe<Site>;
  static fromObject(raw: Maybe<Site.Raw>) {
    return raw ? new Site(raw) : undefined;
  }

  static fromStore(): Site[];
  static fromStore(id: Maybe<Site['id']>): Maybe<Site>;
  static fromStore(id?: Site['id']) {
    const { sites } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(sites)).map(Site.fromObject);
    } else {
      return id ? Site.fromObject(sites[id]) : undefined;
    }
  }

  /** ====================== Instance fields =============================== */
  readonly object_type = 'Site';
  constructor(raw: Site.Raw) {
    Object.assign(this, _.pick(raw, Site.rawFields));
  }

  addServiceDrop(serviceDropId: ServiceDrop['id']) {
    if (this.service_drops?.includes(serviceDropId)) return;
    const serialized = this.serialize();
    const service_drops = serialized.service_drops ?? [];
    const newSite = new Site({
      ...serialized,
      service_drops: [...service_drops, serviceDropId],
    });
    store.dispatch(slices.data.updateModels(newSite));
  }

  get Org() {
    return Org.fromStore(this.org);
  }

  get ServiceDrops() {
    return _.truthy(this.service_drops.map(ServiceDrop.fromStore));
  }

  get Name() {
    return (this.Org?.name || '') + ' ' + (this.name || '');
  }

  get Baselines() {
    return this.baselines.map(BaselineMonth.fromStore);
  }

  serialize(): Site.Serialized {
    return { ..._.pick(this, Site.rawFields), object_type: this.object_type };
  }
}
