import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { Button } from './Button';
import { Card } from './Card';
import { Dialog } from './Dialog';
import { Drawer } from './Drawer';
import { Grid } from './Grid';
import { Loading } from './Loading';
import { TextField } from './TextField';
import { BaselineMonth } from 'onescreen/models';
import { Maybe } from '../types';
import { Box, Typography } from '@material-ui/core';

type ObjWithBaselines = {
  baselines: Array<BaselineMonth['id']>;
  Baselines: Maybe<BaselineMonth>[];
  Name: string;
};
/*================================Types=====================================*/
type BaselineDialogCreateProps<T extends ObjWithBaselines> = {
  objWithBaselines?: T;
  dialogOpen: boolean;
  closeDialog: () => void;
  updateBaselines: (body: { baselines: number[] }) => void;
  defaultBaseline?: number;
};

type BaselineMap = Record<number, BaselineMonth>;

type BaselineCardViewProps<T extends ObjWithBaselines> = {
  objWithBaselines?: T;
  baselineType: string;
  extraInfo: string;
  hasPermission: boolean;
  openBaselineForm: () => void;
};

/*=============================Components====================================*/

export function BaselineForm<T extends ObjWithBaselines>(props: BaselineDialogCreateProps<T>) {
  const [baselines, setBaselines] = useState<BaselineMap>({});
  const { objWithBaselines, dialogOpen, closeDialog, updateBaselines, defaultBaseline } = props;

  useEffect(() => {
    const bls = _.truthy((objWithBaselines?.baselines || []).map(BaselineMonth.fromStore));
    let allBls: BaselineMap = {};
    _.range(1, 13).forEach((month) => {
      allBls[month as BaselineMonth['month']] = (_.find(bls, { month }) ||
        BaselineMonth.fromObject({
          id: 0,
          month: month as BaselineMonth['month'],
          baseline: defaultBaseline !== undefined ? defaultBaseline : 100,
        })) as BaselineMonth;
    });
    setBaselines(allBls);
  }, [objWithBaselines?.baselines, defaultBaseline]);

  if (Object.keys(baselines).length !== 12) {
    return <Loading />;
  }

  return (
    <Drawer open={dialogOpen} onClose={closeDialog} anchor="bottom">
      <Dialog.Content>
        <Typography variant={'h5'}>Baselines for {objWithBaselines?.Name}</Typography>
        <Dialog.ContentText>
          Smart Paste is <b>active</b>: Copy a row of 12 values from a spreadsheet and paste in any
          box to autofill.
        </Dialog.ContentText>
        <Grid>
          {_.sortBy(Object.values(baselines), 'month').map((baseline) => (
            <Grid.Item key={baseline.month} span={1}>
              <TextField
                id={String(baseline.month) + '-field'}
                label={baseline.MonthName}
                type="number"
                value={String(baseline.baseline)}
                onChange={onChangeBaseline(baseline)}
                onPaste={onPasteBaselines}
              />
            </Grid.Item>
          ))}
        </Grid>
      </Dialog.Content>
      <Dialog.Actions>
        <Button onClick={closeDialog} color="primary">
          Cancel
        </Button>
        <Button color="secondary" onClick={onSubmit}>
          Submit
        </Button>
      </Dialog.Actions>
    </Drawer>
  );

  /*=============================Callbacks===================================*/
  function onChangeBaseline(baseline: BaselineMonth) {
    return function (value: string) {
      setBaselines((prevBaselines) => ({
        ...prevBaselines,
        [baseline.month]: BaselineMonth.fromObject({ ...baseline, baseline: Number(value) }),
      }));
    };
  }

  function onSubmit() {
    if (objWithBaselines) {
      const body = {
        baselines: _.sortBy(baselines, 'month').map((baseline) => baseline.baseline),
      };
      updateBaselines(body);
      closeDialog();
    }
  }

  function onPasteBaselines(event: React.ClipboardEvent<HTMLDivElement>) {
    let text = event.clipboardData.getData('Text');
    let textArr = text.split(/\b(\s+)/);
    let numArr: BaselineMonth['baseline'][] = [];
    for (let item of textArr) {
      let num = parseInt(item.replace(',', ''));
      if (num) numArr.push(num);
    }
    if (numArr.length === 12) {
      event.preventDefault();
      const newBls = numArr.map((val, idx) => {
        const month = (idx + 1) as BaselineMonth['month'];
        return new BaselineMonth({ id: 0, month, baseline: val });
      });
      setBaselines(newBls);
    }
  }
}

export function BaselineCard<T extends ObjWithBaselines>(props: BaselineCardViewProps<T>) {
  const { objWithBaselines, baselineType, extraInfo, hasPermission, openBaselineForm } = props;

  return (
    <Card>
      <Grid justify="space-between">
        <Grid.Item>
          <Typography variant="subtitle2">{baselineType} Baselines {extraInfo}</Typography>
        </Grid.Item>
        {hasPermission && (
          <Grid.Item>
            <Button size="small" icon="pencil" onClick={openBaselineForm} />
          </Grid.Item>
        )}
      </Grid>
      <Grid>
        {objWithBaselines?.baselines && (
          <>
            {_.truthy(_.sortBy(objWithBaselines?.Baselines, 'month')).map((baseline) => {
              const date = new Date();
              date.setMonth(baseline.month - 1);
              return (
                <Grid.Item key={baseline.id} span={1}>
                  <Box borderRight={1} borderBottom={1} paddingRight={4}>
                    <Typography variant="caption">
                      {date.toLocaleString('default', { month: 'short' })}
                    </Typography>
                  </Box>
                  <Box borderRight={1} paddingRight={4}>
                    <Typography variant="caption">{baseline.baseline}</Typography>
                  </Box>
                </Grid.Item>
              );
            })}
          </>
        )}
      </Grid>
    </Card>
  );
}
