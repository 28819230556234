import React, { useMemo, useState } from 'react';
import _ from 'lodash';

import { TextField } from './TextField';
import { Button } from './Button';
import { Typography, TypographyProps } from './Typography';
import { Box } from './';
import { Item, Container } from './Flex';
import { Grid } from './Grid';
import { makeStylesHook } from '../styles';
import { Select, MultiSelect } from './Select';
import { Checkbox } from './Checkbox';

/** ================================ Types ================================= */

type InfoCardFormPayload = {
  [key: string]: any;
};
type InfoCardSelectChoices = { value: any; label: string };
type InfoCardSelectOptions = {
  choices: InfoCardSelectChoices[];
  allowBlank?: boolean;
  blankValue?: any;
  multi?: boolean;
};
type InfoCardItem = {
  value: any;
  label: string;
  key: string;
  type?: 'text' | 'select' | 'number' | 'checkbox';
  largerText?: boolean;
  bolderText?: boolean;
  editable?: boolean;
  readOnly?: boolean;
  selectOptions?: InfoCardSelectOptions;
  secondaryAction?: React.ReactElement;
};
type RowOpts = {
  className?: string;
  variant?: TypographyProps['variant'];
  emphasis?: TypographyProps['emphasis'];
};
type infoCardStaticItemProps = {
  itemText: string;
  rowOpts: RowOpts;
  secondaryAction: InfoCardItem['secondaryAction'];
};
export type InfoCardFormProps = {
  items: InfoCardItem[];
  callback?: (payload: any) => void;
};

/** ======================== Styles ======================================== */
const useStyles = makeStylesHook(
  () => ({
    disabledText: {
      color: 'grey',
    },
  }),
  'OneScreenFormItem'
);

/** ============================= Components =============================== */
export const InfoCardStaticItem: React.FC<infoCardStaticItemProps> = ({
  itemText,
  rowOpts,
  secondaryAction,
}) => {
  return (
    <Container alignItems="center" direction="row">
      <Item>
        <Typography {...rowOpts}>{itemText}</Typography>
      </Item>
      {secondaryAction && <Item>{secondaryAction}</Item>}
    </Container>
  );
};

export const InfoCardForm: React.FC<InfoCardFormProps> = (props) => {
  const { items, callback } = props;
  const [editing, setEditing] = useState(false);
  let p = useMemo<InfoCardFormPayload>(() => ({}), []);
  items.forEach((item) => {
    if (!(item.editable === false) && !item.readOnly) {
      p[item.key] = item.value;
    }
  });
  const [payload, setPayload] = useState<InfoCardFormPayload>(p);
  const classes = useStyles();

  let itemRows = [];
  for (let item of _.truthy(items)) {
    if (item.editable === false && editing) continue;
    let rowOpts: RowOpts = {};
    let shouldEdit = true;
    if (item.readOnly) {
      rowOpts['className'] = classes.disabledText;
      shouldEdit = false;
    }
    if (item.largerText) rowOpts['variant'] = 'h5';
    if (item.bolderText === false) rowOpts['emphasis'] = 'normal';
    if (item.editable === false) shouldEdit = false;
    let valueHtml;

    if (item.type && item.type === 'select' && item.selectOptions) {
      const blankValue = _.isUndefined(item.selectOptions.blankValue)
        ? 0
        : item.selectOptions.blankValue;
      const options = item.selectOptions.allowBlank
        ? [{ value: blankValue, label: ' ' }].concat(item.selectOptions.choices)
        : item.selectOptions.choices;

      if (item.selectOptions.multi) {
        const selectedOptions = _.filter(options, (option) => {
          return _.includes(payload[item.key], option.value);
        });

        valueHtml =
          editing && shouldEdit ? (
            <MultiSelect
              fullWidth
              options={options}
              value={selectedOptions}
              onChange={(value) => setPayload((p) => ({ ...p, [item.key]: _.map(value, 'value') }))}
              renderOption="label"
              valueOption="value"
            />
          ) : (
            <InfoCardStaticItem
              itemText={_.map(selectedOptions, 'label').join(', ')}
              rowOpts={rowOpts}
              secondaryAction={item.secondaryAction}
            />
          );
      } else {
        valueHtml =
          editing && shouldEdit ? (
            <Select
              fullWidth
              options={options}
              value={_.find(options, { value: payload[item.key] })}
              onChange={(value) => setPayload((p) => ({ ...p, [item.key]: value.value }))}
              renderOption="label"
              valueOption="value"
            />
          ) : (
            <InfoCardStaticItem
              itemText={_.find(options, { value: item.value })?.label!}
              rowOpts={rowOpts}
              secondaryAction={item.secondaryAction}
            />
          );
      }
    } else if (item.type && item.type === 'checkbox') {
      valueHtml =
        editing && shouldEdit ? (
          <Checkbox
            label=""
            checked={!!payload[item.key]}
            onChange={() => setPayload((p) => ({ ...p, [item.key]: !p[item.key] }))}
          />
        ) : (
          <Checkbox {...rowOpts} label="" checked={!!item.value} disabled={true} />
        );
    } else {
      valueHtml =
        editing && shouldEdit ? (
          <TextField
            type={item.type ? item.type : 'text'}
            value={payload[item.key] as string}
            onChange={(value) => setPayload((p) => ({ ...p, [item.key]: value }))}
            name={item.key}
          />
        ) : (
          <InfoCardStaticItem
            itemText={item.value}
            rowOpts={rowOpts}
            secondaryAction={item.secondaryAction}
          />
        );
    }
    itemRows.push(
      <Grid key={item.key}>
        <Grid.Item span={4}>
          <Typography emphasis="bold" {...rowOpts}>
            <Box textAlign="right">{item.label}:</Box>
          </Typography>
        </Grid.Item>
        <Grid.Item span={8}>{valueHtml}</Grid.Item>
      </Grid>
    );
  }

  let cornerButton = callback ? (
    <Button size="small" icon="pencil" onClick={toggleEditing} />
  ) : null;
  if (editing)
    cornerButton = (
      <Box>
        <Button size="small" icon="close" onClick={toggleEditing} />
        <Button size="small" color="secondary" icon="send" onClick={onSave} />
      </Box>
    );
  return (
    <>
      <Grid justify="flex-end">
        <Grid.Item>{cornerButton}</Grid.Item>
      </Grid>
      {itemRows}
    </>
  );
  /** ================================== Callbacks ========================= */
  function toggleEditing() {
    if (editing) {
      setPayload(p);
    }
    setEditing((e) => !e);
  }
  async function onSave() {
    if (callback) {
      callback(payload);
      toggleEditing();
    }
  }
};
