import _ from 'lodash';

import { slices, store } from 'onescreen/store';
import { Maybe, ObjectWithoutType, Pagination, Query } from 'onescreen/types';

import { BaseAPI } from '../base';
import { TimeStamp } from '../dates';
import { IntervalFile } from '../interval';
import { MonitoringPlatform, MonitoringPlatformAccountType } from '../monitoring';
import { ServiceDrop } from '../orgs';
import { BaselineMonth } from '../util';
import { KnownIssue } from './known_issue';

/** ======================== Types ========================================= */
type CommonAttrs = {
  id: number;
  name: string;
  object_type: 'SolarGenerator';
  service_drop: ServiceDrop['id'];
  known_issues: KnownIssue[];
  monitors: MonitoringPlatform['id'][];
  ctype_id: number;
  interval_file: IntervalFile['id'];
  baselines: Array<BaselineMonth['id']>;
  monitor_types: Array<MonitoringPlatformAccountType['id']>;
  capacity: Maybe<number> | null;
};

export declare namespace SolarGenerator {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs {}

  // Network interface
  export namespace API {
    export type ListParams = RetrieveParams & Query.PaginationParams;
    export type ListResponse = Pagination.Response.Raw<{
      solar_generators: SolarGenerator.Raw[];
    }>;
    export type ListResult = Pagination.Response.Parsed<SolarGenerator>;

    export type RetrieveParams = Query.DynamicRestParams;
    export type RetrieveResponse = { solar_generator: SolarGenerator.Raw };
    export type RetrieveResult = { solarGenerator: SolarGenerator };

    export type UpdateParams = {
      baselines?: number[];
      capacity?: number | "" | null;
    };
  }
}

/** ======================== API =========================================== */
class SolarGeneratorAPI extends BaseAPI {
  private static route = BaseAPI.endpoints.v1.solarGenerator;

  static async list(
    params?: SolarGenerator.API.ListParams
  ): Promise<SolarGenerator.API.ListResult> {
    const { json } = await this.get<SolarGenerator.API.ListResponse>(this.route, params);

    // Parse response
    const solar_generators = this.parsePaginationSet(json, ({ solar_generators }) =>
      SolarGenerator.fromObjects(solar_generators)
    );
    store.dispatch(slices.data.updateModels(solar_generators.results));
    return solar_generators;
  }

  static async retrieve(
    id: SolarGenerator.Raw['id'],
    params?: SolarGenerator.API.RetrieveParams
  ): Promise<SolarGenerator.API.RetrieveResult> {
    const { json } = await this.get<SolarGenerator.API.RetrieveResponse>(this.route(id), params);

    // Parse response
    const solarGenerator = new SolarGenerator(json.solar_generator);
    store.dispatch(slices.data.updateModels(solarGenerator));
    return { solarGenerator };
  }

  static async create(): Promise<SolarGenerator.API.RetrieveResult> {
    const { json } = await this.post<SolarGenerator.API.RetrieveResponse>(this.route);

    const solarGenerator = new SolarGenerator(json.solar_generator);
    store.dispatch(slices.data.updateModels(solarGenerator));
    return { solarGenerator };
  }

  static async update(
    id: SolarGenerator.Raw['id'],
    body: SolarGenerator.API.UpdateParams,
    params?: SolarGenerator.API.RetrieveParams
  ): Promise<SolarGenerator.API.RetrieveResult> {
    const { json } = await this.patch(this.route(id), body, params);
    const { solar_generator, baseline_months } = json;
    store.dispatch(slices.data.updateModels(BaselineMonth.fromObjects(baseline_months)));
    store.dispatch(slices.data.updateModels(SolarGenerator.fromObject(solar_generator)));

    return solar_generator;
  }
}

/** ======================== Model ========================================= */
export interface SolarGenerator extends TimeStamp.Parsed, CommonAttrs {}
export class SolarGenerator {
  /** ====================== Static fields ================================= */
  static api = SolarGeneratorAPI;
  static readonly rawFields = [
    'id',
    'name',
    'service_drop',
    'known_issues',
    'monitors',
    'ctype_id',
    'interval_file',
    'baselines',
    'monitor_types',
    'capacity',
  ] as const;

  /**
   * Parses an array of raw SolarGenerator objects by passing each in turn to SolarGenerator.fromObject. Note that calling
   * `raw.map(this.fromObject)` does not work, because TypeScript is unable to resolve the correct
   * overload for fromObject when it is passed to the `map` method. This is a TypeScript design
   * limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {SolarGenerator.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: SolarGenerator.Raw[]): SolarGenerator[];
  static fromObjects(raw: Maybe<SolarGenerator.Raw[]>): Maybe<SolarGenerator[]>;
  static fromObjects(raw: Maybe<SolarGenerator.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: SolarGenerator.Raw): SolarGenerator;
  static fromObject(raw: Maybe<SolarGenerator.Raw>): Maybe<SolarGenerator>;
  static fromObject(raw: Maybe<SolarGenerator.Raw>) {
    return raw ? new SolarGenerator(raw) : undefined;
  }

  static fromStore(): SolarGenerator[];
  static fromStore(id: Maybe<SolarGenerator['id']>): Maybe<SolarGenerator>;
  static fromStore(id?: SolarGenerator['id']) {
    const { solarGenerators } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(solarGenerators)).map(SolarGenerator.fromObject);
    } else {
      return id ? SolarGenerator.fromObject(solarGenerators[id]) : undefined;
    }
  }

  /** ====================== Instance fields =============================== */
  readonly object_type = 'SolarGenerator';
  constructor(raw: SolarGenerator.Raw) {
    Object.assign(this, _.pick(raw, SolarGenerator.rawFields));
  }

  /**
   * Adds a MonitoringPlatform object to the device and updates the store model
   *
   * @param {MonitoringPlatform} monitoringPlatform: the MonitoringPlatform model to add to the SolarGenerator
   */
  addMonitoringPlatform(monitoringPlatform: MonitoringPlatform) {
    // If the monitors array already includes this MonitoringPlatform object, no update necessary
    if (this.monitors?.includes(monitoringPlatform.id)) return;

    const serialized = this.serialize();
    const monitors = serialized.monitors ?? [];
    const newSolarGenerator = new SolarGenerator({
      ...serialized,
      monitors: [...monitors, monitoringPlatform.id],
    });

    store.dispatch(slices.data.updateModels(newSolarGenerator));
  }

  /**
   * Removes a MonitoringPlatform object from the device and updates the store model
   *
   * @param {MonitoringPlatform.id} monitoringPlatformId: the MonitoringPlatform model to remove from the SolarGenerator
   */
  removeMonitoringPlatform(monitoringPlatformId: MonitoringPlatform['id']) {
    // If the monitors array doesn't include this MonitoringPlatform object, no update necessary
    if (!this.monitors?.includes(monitoringPlatformId)) return;

    const serialized = this.serialize();
    let monitors = [...serialized.monitors] ?? [];
    const indexToDelete = monitors.indexOf(monitoringPlatformId);
    monitors.splice(indexToDelete, 1);
    const newSolarGenerator = new SolarGenerator({
      ...serialized,
      monitors,
    });

    store.dispatch(slices.data.updateModels(newSolarGenerator));
  }

  get ServiceDrop() {
    return ServiceDrop.fromStore(this.service_drop);
  }

  get Monitors() {
    return this.monitors.map(MonitoringPlatform.fromStore);
  }

  get Name() {
    return (
      (this.ServiceDrop?.Site?.Org?.name || '') +
      ' ' +
      (this.ServiceDrop?.Site?.name || '') +
      ' ' +
      (this.ServiceDrop?.name || '')
    );
  }

  get Baselines() {
    return this.baselines.map(BaselineMonth.fromStore);
  }

  get MonitorTypes() {
    return _.truthy((this.monitor_types || []).map(MonitoringPlatformAccountType.fromStore));
  }

  serialize(): SolarGenerator.Serialized {
    return {
      ..._.pick(this, SolarGenerator.rawFields),
      object_type: this.object_type,
    };
  }
}
