import _ from 'lodash';

import { slices, store } from 'onescreen/store';
import { Maybe, ObjectWithoutType, Pagination, Query } from 'onescreen/types';

import { BaseAPI } from '../base';
import { TimeStamp } from '../dates';
import { IntervalFile } from '../interval';
import { MonitoringPlatform, MonitoringPlatformAccountType } from '../monitoring';
import { ServiceDrop } from '../orgs';
import { KnownIssue } from './known_issue';

/** ======================== Types ========================================= */
type CommonAttrs = {
  id: number;
  name: string;
  object_type: 'Storage';
  service_drop: ServiceDrop['id'];
  known_issues: KnownIssue[];
  monitors: MonitoringPlatform['id'][];
  ctype_id: number;
  interval_file: IntervalFile['id'];
  monitor_types: Array<MonitoringPlatformAccountType['id']>;
  capacity: Maybe<number> | null;
};

export declare namespace Storage {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs {}

  // Network interface
  export namespace API {
    export type ListParams = RetrieveParams & Query.PaginationParams;
    export type ListResponse = Pagination.Response.Raw<{
      storages: Storage.Raw[];
    }>;
    export type ListResult = Pagination.Response.Parsed<Storage>;

    export type RetrieveParams = Query.DynamicRestParams;
    export type RetrieveResponse = { storage: Storage.Raw };
    export type RetrieveResult = { storage: Storage };

    export type UpdateParams = {
      capacity?: number | "" | null;
    };
  }
}

/** ======================== API =========================================== */
class StorageAPI extends BaseAPI {
  private static route = BaseAPI.endpoints.v1.storage;

  static async list(params?: Storage.API.ListParams): Promise<Storage.API.ListResult> {
    const { json } = await this.get<Storage.API.ListResponse>(this.route, params);

    // Parse response
    const storages = this.parsePaginationSet(json, ({ storages }) => Storage.fromObjects(storages));
    store.dispatch(slices.data.updateModels(storages.results));
    return storages;
  }

  static async create(): Promise<Storage.API.RetrieveResult> {
    const { json } = await this.post<Storage.API.RetrieveResponse>(this.route);

    const storage = new Storage(json.storage);
    store.dispatch(slices.data.updateModels(storage));
    return { storage };
  }

  static async retrieve(
    id: Storage.Raw['id'],
    params?: Storage.API.RetrieveParams
  ): Promise<Storage.API.RetrieveResult> {
    const { json } = await this.get<Storage.API.RetrieveResponse>(this.route(id), params);

    // Parse response
    const storage = new Storage(json.storage);
    store.dispatch(slices.data.updateModels(storage));
    return { storage };
  }

  static async update(
    id: Storage.Raw['id'],
    body: Storage.API.UpdateParams,
    params?: Storage.API.RetrieveParams
  ): Promise<Storage.API.RetrieveResult> {
    const { json } = await this.patch(this.route(id), body, params);
    const { storage } = json;
    store.dispatch(slices.data.updateModels(Storage.fromObject(storage)));

    return storage;
  }

}

/** ======================== Model ========================================= */
export interface Storage extends TimeStamp.Parsed, CommonAttrs {}
export class Storage {
  /** ====================== Static fields ================================= */
  static api = StorageAPI;
  static readonly rawFields = [
    'id',
    'name',
    'service_drop',
    'known_issues',
    'monitors',
    'ctype_id',
    'interval_file',
    'monitor_types',
    'capacity',
  ] as const;

  /**
   * Parses an array of raw Storage objects by passing each in turn to Storage.fromObject. Note that calling
   * `raw.map(this.fromObject)` does not work, because TypeScript is unable to resolve the correct
   * overload for fromObject when it is passed to the `map` method. This is a TypeScript design
   * limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {Storage.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: Storage.Raw[]): Storage[];
  static fromObjects(raw: Maybe<Storage.Raw[]>): Maybe<Storage[]>;
  static fromObjects(raw: Maybe<Storage.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: Storage.Raw): Storage;
  static fromObject(raw: Maybe<Storage.Raw>): Maybe<Storage>;
  static fromObject(raw: Maybe<Storage.Raw>) {
    return raw ? new Storage(raw) : undefined;
  }

  static fromStore(): Storage[];
  static fromStore(id: Maybe<Storage['id']>): Maybe<Storage>;
  static fromStore(id?: Storage['id']) {
    const { storages } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(storages)).map(Storage.fromObject);
    } else {
      return id ? Storage.fromObject(storages[id]) : undefined;
    }
  }

  /** ====================== Instance fields =============================== */
  readonly object_type = 'Storage';
  constructor(raw: Storage.Raw) {
    Object.assign(this, _.pick(raw, Storage.rawFields));
  }

  /**
   * Adds a MonitoringPlatform object to the device and updates the store model
   *
   * @param {MonitoringPlatform} monitoringPlatform: the MonitoringPlatform model to add to the Storage
   */
  addMonitoringPlatform(monitoringPlatform: MonitoringPlatform) {
    // If the monitors array already includes this MonitoringPlatform object, no update necessary
    if (this.monitors?.includes(monitoringPlatform.id)) return;

    const serialized = this.serialize();
    const monitors = serialized.monitors ?? [];
    const newStorage = new Storage({
      ...serialized,
      monitors: [...monitors, monitoringPlatform.id],
    });

    store.dispatch(slices.data.updateModels(newStorage));
  }

  /**
   * Removes a MonitoringPlatform object from the device and updates the store model
   *
   * @param {MonitoringPlatform.id} monitoringPlatformId: the MonitoringPlatform model to remove from the Storage
   */
  removeMonitoringPlatform(monitoringPlatformId: MonitoringPlatform['id']) {
    // If the monitors array doesn't include this MonitoringPlatform object, no update necessary
    if (!this.monitors?.includes(monitoringPlatformId)) return;

    const serialized = this.serialize();
    const monitors = [...serialized.monitors] ?? [];
    const indexToDelete = monitors.indexOf(monitoringPlatformId);
    monitors.splice(indexToDelete, 1);
    const newStorage = new Storage({
      ...serialized,
      monitors,
    });

    store.dispatch(slices.data.updateModels(newStorage));
  }

  get ServiceDrop() {
    return ServiceDrop.fromStore(this.service_drop);
  }

  get Monitors() {
    return this.monitors.map(MonitoringPlatform.fromStore);
  }

  get MonitorTypes() {
    return _.truthy((this.monitor_types || []).map(MonitoringPlatformAccountType.fromStore));
  }

  serialize(): Storage.Serialized {
    return {
      ..._.pick(this, Storage.rawFields),
      object_type: this.object_type,
    };
  }
}
