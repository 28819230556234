import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router';

import {
  FilterList,
  InfoCardForm,
  GenericForm,
  Loading,
  Grid,
  Card,
  InfoCardFormProps,
  Icon,
  Typography,
  Dialog,
  Button,
} from 'onescreen/components';
import { useDialogState, useSite } from 'onescreen/hooks';
import { BaselineMonth, ServiceDrop, Site } from 'onescreen/models';
import { HasUserPermissions } from 'onescreen/hooks/user';
import { SiteParam } from 'onescreen/types';
import { BaselineCard, BaselineForm } from 'onescreen/components/BaselineForm';

export default function SiteReport() {
  const { siteId } = useParams<SiteParam>() as SiteParam;
  const [addModalOpen, openAddModal, closeAddModal] = useDialogState();
  const [baselineFormOpen, openBaselineForm, closeBaselineForm] = useDialogState();
  const { pathname } = useLocation();
  const [, setIrradianceBaselines] = useState<Array<BaselineMonth['id']>>();

  const { site } = useSite(+siteId, {
    include: ['service_drops.*', 'org.*', 'baselines.*'],
  });

  if (!site) return <Loading />;

  const serviceDropSecondaryFunc = (sd: ServiceDrop) => {
    const hasMeters = !!sd?.meters?.length;
    const hasSolar = !!sd?.solar_generator;
    const hasStorage = !!sd?.storage;
    return (
      <>
        <Typography emphasis={hasMeters ? 'normal' : 'disabled'}>
          <Icon name="plug" />
        </Typography>
        <Typography emphasis={hasSolar ? 'normal' : 'disabled'}>
          <Icon name="sun" />
        </Typography>
        <Typography emphasis={hasStorage ? 'normal' : 'disabled'}>
          <Icon name="battery" />
        </Typography>
      </>
    );
  };

  const hasOrgPermission = HasUserPermissions(['OrgEditors', 'OrgFullAccessEditors']);
  const hasMonitorFullPermission = HasUserPermissions(['MonitorFullAccessEditors']);
  const hasMonitorViewPermission = HasUserPermissions([
    'MonitorViewers',
    'MonitorEditors',
    'MonitorFullAccessEditors',
  ]);

  const infoCardItems: InfoCardFormProps['items'] = [
    {
      key: 'name',
      label: 'Site Name',
      value: site.name,
      largerText: true,
    },
    {
      key: 'id',
      label: 'ID',
      value: site.id,
      readOnly: true,
    },
    {
      key: 'address',
      label: 'Address',
      value: site.address,
    },
    {
      key: 'address_2',
      label: 'Address 2',
      value: site.address_2,
    },
    { key: 'city', label: 'City', value: site.city },
    { key: 'state', label: 'State', value: site.state },
    {
      key: 'zipcode',
      label: 'Zip',
      value: site.zipcode,
      type: 'number',
    },
  ];
  return (
    <>
      <Grid.Item span={10}>
        <Card>
          <Card.Content>
            <Grid justify="center">
              <Grid.Item span={12}>
                <InfoCardForm
                  items={infoCardItems}
                  callback={hasOrgPermission ? updateSite : undefined}
                />
              </Grid.Item>
              {!!site.zipcode && hasMonitorViewPermission && (
                // Display this card only for users with
                // view access to Monitoring page.
                <Grid.Item span={4}>
                  <BaselineCard
                    objWithBaselines={site}
                    baselineType="Irradiance"
                    extraInfo=""
                    // Since this form affects all sites sharing a zipcode
                    // allow editing it only if the user has edit permission
                    // to Org and Monitoring pages.
                    hasPermission={hasOrgPermission && hasMonitorFullPermission}
                    openBaselineForm={openBaselineForm}
                  />
                </Grid.Item>
              )}
              <Grid.Item span={10}>
                <FilterList
                  dense={true}
                  title="Service Drops"
                  url={`${pathname}/service-drop`}
                  data={site.ServiceDrops || []}
                  secondaryFunc={serviceDropSecondaryFunc}
                  onClickAdd={hasOrgPermission ? openAddModal : undefined}
                />
              </Grid.Item>
            </Grid>
          </Card.Content>
        </Card>
      </Grid.Item>
      <BaselineForm
        objWithBaselines={site}
        dialogOpen={baselineFormOpen}
        closeDialog={closeBaselineForm}
        updateBaselines={updateSite}
        defaultBaseline={0}
      />
      <Dialog open={addModalOpen} onClose={closeAddModal}>
        <Dialog.Content>
          <GenericForm
            title="Create a new Service Drop"
            fields={[
              {
                label: 'Name',
                key: 'name',
              },
              {
                label: 'Address',
                key: 'address',
              },
            ]}
            callback={createServiceDrop}
          />
        </Dialog.Content>
        <Dialog.Actions>
          <Button onClick={closeAddModal}>Cancel</Button>
        </Dialog.Actions>
      </Dialog>
    </>
  );
  /** ============================== Callbacks ============================= */
  async function updateSite(payload: Site.API.UpdateParams) {
    Site.api.update(+siteId, payload, { include: ['baselines.*'] })
      // Need to do this so new value gets reflected in the UI.
      .then(() => { setIrradianceBaselines(site?.baselines) });
  }

  async function createServiceDrop(payload: ServiceDrop.API.CreateParams) {
    await ServiceDrop.api.create({ ...payload, site: +siteId });
    closeAddModal();
  }
}
